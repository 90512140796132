import React, { useEffect } from 'react';


import Footer from './Footer';

import gal1 from '../img/gal1 (1).JPG';
import gal2 from '../img/gal1 (2).JPG';
import gal3 from '../img/gal1 (3).JPG';
import gal4 from '../img/gal1 (4).JPG';
import gal5 from '../img/gal1 (5).JPG';
import gal6 from '../img/gal1 (6).JPG';
import gal7 from '../img/gal 7.jpeg';
import gal8 from '../img/gal8.jpeg';
import gal9 from '../img/gal9.jpeg';
import gal10 from '../img/gal10 (1).jpeg'
import gal11 from '../img/gal10 (2).jpeg'

import gal12 from '../img/newgal (1).jpeg'
import gal13 from '../img/newgal (2).jpeg'
import gal14 from '../img/newgal (3).jpeg'

import gal15 from '../img/new (1).jpeg'
import gal16 from '../img/new (2).jpeg'

import gal17 from '../img/newgals.jpg'

import gal18 from '../img/funct1.jpg'
import gal19 from '../img/funct2.jpg'

const Gallery = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  
  const images = [gal4,  gal6, gal2, gal5, gal1, gal3, gal9,gal10, gal11,gal12,gal13,gal14,gal15,gal16,gal17,gal18,gal19];

  return (
    <>
      <div className='Heading    '>
        <h1>Gallery</h1>
        <hr className="styled-hr" />
       
      </div>

      <div className="gallery-container">
        {images.map((img, index) => (
          <div key={index} className="gallery-item">
            <img src={img} alt={`Gallery ${index + 1}`} />
          </div>
        ))}
      </div>

      <div className='opration'>
      <center><img src={gal8}/></center>

      </div>
      <Footer />
    </>
  );
};

export default Gallery;
